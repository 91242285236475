import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

export function adjustElementHeight(current) {
    const rect = current.getBoundingClientRect(); // Get the position information of the element
    const screenHeight = window.innerHeight; // Window inner height in pixels
    const desiredHeight = screenHeight - rect.top; // Subtract the top position from the window height

    current.style.overflow = "auto";
    current.style.height = desiredHeight + 'px';
}

export function addSimplebarEventListener(componantName) {
    document.addEventListener("component-mounted", function (event) {
        if(event.detail.componentName === componantName) {
            let sidebar = new SimpleBar(event.detail.current, {
                autoHide: false,
            });
            // adjustElementHeight(event.detail.current);
        }
    });
}