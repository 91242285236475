import { Component } from "../componant";
import { TransportTabContentComponent } from "./transport-tab-content-component";
import { JourneyTabContentComponent } from "./journey-tab-content-component";
import { AddressTabContentComponent } from "./address-tab-content-component";

export function TabContentContainerComponent(props) {
  Component.call(this, props);
  this.state = {
    selectedTabId: props.selectedTabId,
  };
}

TabContentContainerComponent.prototype = Object.create(Component.prototype);

TabContentContainerComponent.prototype.setSelectedTabId = function (id) {
  this.setState({
    selectedTabId: id,
  });
};

TabContentContainerComponent.prototype.render = function () {
  let content;

  if (this.state.selectedTabId === "pt_object_title") {
    content = new TransportTabContentComponent({
      title: "test1",
      selected: true,
    });
  } else if (this.state.selectedTabId === "tab_journey_title") {
    content = new JourneyTabContentComponent({
      title: "test12",
      selected: true,
    });
  } else if (this.state.selectedTabId === "address_title") {
    content = new AddressTabContentComponent({
      title: "test13",
      selected: true,
    });
  }

  const container = document.createElement("div");
  container.id = "tab_content_container";

  content.mount(container);

  return container;
};
