let instance = null;

export function getAccessibilitySubscriber() {
    if (!instance) {
        instance = new AccessibilitySubscriber();
    }
    return instance;
}

export function getAccessibilityEnterAwareClassname() {
    return 'enteraware';
}

function AccessibilitySubscriber() {
    this.enterHandler = this.enterHandler.bind(this);
    this.subscribed = false;
}

AccessibilitySubscriber.prototype.subscribe = function () {
    if (!this.subscribed) {
        this.subscribed = true;

        JKisio(document).on('keyup', '.enteraware',this.enterHandler);
        document.addEventListener("component-mounted", this.focusOnComponent);
    }
};

AccessibilitySubscriber.prototype.unsubscribe = function () {
    if (this.subscribed) {
        JKisio(document).off('keyup', '.enteraware',this.enterHandler);
        document.removeEventListener("component-mounted", this.focusOnComponent);
    }
};

AccessibilitySubscriber.prototype.enterHandler = function (event) {
    let target = event.target;
    if (event.keyCode == 32 || event.keyCode == 13) {
        event.stopPropagation();
        JKisio(target).trigger('click');
    }
};


AccessibilitySubscriber.prototype.focusOnComponent = function (event) {
    if(event.detail.current && event.detail.self.shouldBeFocusOn && event.detail.self.shouldBeFocusOn === true) {
        event.detail.current.focus();
    }
};
