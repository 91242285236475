import { ControllerInterface } from "./controller-interface";
import { BookmarkTitleComponent } from "../components/bookmark-component/bookmark-title-component";
import { BookmarkClientFactory } from "../client/bookmark-client-factory";
import { TabContentContainerComponent } from "../components/bookmark-component/tab-content-container-component";
import { NoBookmarkTabContentComponent } from "../components/bookmark-component/no-bookmark-tab-content-component";
import { BookmarkAddressController } from "./bookmark-address-controller";

export function BookmarkHomeController() {
  this.bookmarkClient = new BookmarkClientFactory().createClient();
}

// Héritage du prototype de l'interface
BookmarkHomeController.prototype = Object.create(ControllerInterface);

/**
 * Default route, instanciate bookmark tabulation and take the default one
 * -> Composant title
 * -> Composant tab
 * @param {Element} rootElement
 * @param {object} params
 */
BookmarkHomeController.prototype.init = function (rootElement, params) {
  this.rootElement = rootElement;

  let tabContentContainer;

  let defaultTabId = params.tabId || "pt_object_title";

  const bookmarkTitleComponent = new BookmarkTitleComponent({
    selectedTabId: defaultTabId,
    onChange: (tabId) => {
      if (typeof tabContentContainer !== "undefined") {
        tabContentContainer.setSelectedTabId(tabId);
      }
    },
  });

  bookmarkTitleComponent.mount(rootElement);

  tabContentContainer = new TabContentContainerComponent({
    selectedTabId: bookmarkTitleComponent.state.selectedTabId,
  });

  tabContentContainer.mount(rootElement);
};

BookmarkHomeController.prototype.destroy = function () {
  this.rootElement.innerHTML = "";
};
