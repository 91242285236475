import { ControllerInterface } from "../controller/controller-interface";

export function Router() {
  if (Router.instance) {
    return Router.instance;
  }

  Router.instance = this;

  this.routes = {};
  this.currentController = null;
  this.rootElement = null;
  this.paramNames = {};
}

Router.prototype.add = function (path, controller) {
  const paramNames = [];
  const regexPath = path.replace(/:([\w]+)/g, (_, name) => {
    paramNames.push(name);
    return "([^\\/]+)";
  });

  this.routes[regexPath] = controller;
  this.paramNames[regexPath] = paramNames;
};

/**
 * Routes to the controller for the given path.
 * @param {string} path - The path of the controller to route to.
 */
Router.prototype.route = function (path) {
  // Match the given path to a registered route, or throw an error if it doesn't exist.
  const matchedPath = this.match(path);

  if (!matchedPath) {
    throw new Error(`Cannot find a controller for path ${path}`);
  }

  const [regexPath, params] = matchedPath;
  const controllerPrototype = this.routes[regexPath];

  // Create a new instance of the controller.
  let controllerInstance = new controllerPrototype();

  // Destroy the current controller.
  this.destroyController();

  // Check if the controller implements the ControllerInterface.
  if (
    typeof controllerInstance === "object" &&
    !ControllerInterface.isPrototypeOf(controllerInstance)
  ) {
    throw new Error(
      `Le controller doit implémenter l'interface ControllerInterface.`
    );
  }

  // Set the current controller to the one for the matched path, and initialize it with the extracted route parameters.
  this.currentController = controllerInstance;
  controllerInstance.init(this.rootElement, params);
};

Router.prototype.match = function (path) {
  for (const regexPath in this.routes) {
    const pattern = new RegExp(`^${regexPath}$`);
    const match = pattern.exec(path);

    if (match) {
      const params = {};
      const paramNames = this.paramNames[regexPath];

      for (let i = 1; i < match.length; i++) {
        params[paramNames[i - 1]] = match[i];
      }

      return [regexPath, params];
    }
  }

  return null;
};

/**
 * Initializes the router with the given configuration and root element.
 * @param {Array} config - The configuration to use for initializing the router.
 * @param {HTMLElement} rootElement - The root element for the application.
 */
Router.prototype.init = function (config, rootElement) {
  const self = this;

  config.forEach(function (route) {
    self.add(route.path, route.controller);
  });

  self.rootElement = rootElement;
};

/**
 * Destroys the current controller.
 */
Router.prototype.destroyController = function () {
  if (null !== this.currentController) {
    this.currentController.destroy();
    this.currentController = null;
  }
};
