import { ControllerInterface } from "./controller-interface";

export function UserConsentDisabledMessageController() {
  this.rootElement = null;
}

// Héritage du prototype de l'interface
UserConsentDisabledMessageController.prototype =
  Object.create(ControllerInterface);

/**
 *
 * @param {HTMLElement} rootElement
 */
UserConsentDisabledMessageController.prototype.init = function (rootElement) {
  this.rootElement = rootElement;
  rootElement.innerHTML =
    '<div id="list-favorites">\n' +
    '         <ul class="list-fav">\n' +
    "            <li>\n" +
    "               Vous n'avez pas donné votre consentemeent                           \n" +
    "            </li>\n" +
    "         </ul>\n" +
    "      </div>";
};

UserConsentDisabledMessageController.prototype.destroy = function () {
  // Code pour nettoyer la page des favoris désactivés
  this.rootElement.innerHTML = "";
};
