import { Component } from "../componant";

export function ReturnBookmarkComponent(props) {
  Component.call(this, props);
}

ReturnBookmarkComponent.prototype = Object.create(Component.prototype);

ReturnBookmarkComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.classList.add("return-bookmark");

  const a = document.createElement("a");
  a.innerHTML = Translator.trans(
    this.props.message ?? "bookmark.section.link.return"
  );
  a.href = "#";

  const icon = document.createElement("i");
  icon.classList.add("ikisio", "ikisio-back");
  //icon.textContent = "← ";

  a.prepend(icon);

  container.addEventListener("click", (event) => {
    window.AppBookmarkInstance.router.route(this.props.path);
  });

  container.appendChild(a);

  return container;
};

ReturnBookmarkComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  this.dom = container;
  this.current = current;
};
