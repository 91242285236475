import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export function Address() {
  this.internal = {
    id: null,
    type: "address",
    data: {
      coord: null, // just inverse of id, calculated
      name: null, // given
      icon: null, // calculated
      address: null, // given
      latitude: null, // given
      longitude: null, // given
    },
  };
}

/**
 * @param name
 * @param address
 * @param latitude
 * @param longitude
 * @returns {Address}
 */
Address.prototype.setAddress = function (name, address, latitude, longitude) {
  this.internal.id = `${longitude};${latitude}`;
  // yes, you saw it right. there is two way of storing it in the journey section coord & id are different !
  this.internal.data.coord = `${latitude};${longitude}`;
  this.internal.data.name = name;
  // todo
  this.internal.data.icon = this.guessIconByName(name);
  this.internal.data.address = address;
  this.internal.data.latitude = latitude;
  this.internal.data.longitude = longitude;
  return this;
};

Address.prototype.guessIconByName = function (name) {
  switch (true) {
    case name.toLowerCase().includes(Translator.trans("bookmark.home")):
      return "ikisio-home";
    case name.toLowerCase().includes(Translator.trans("bookmark.work")):
      return "ikisio-work";
    default:
      return "ikisio-address";
  }
};

Address.prototype.getNavitiaObject = function () {
  return this.internal;
};

/**
 * @param {Element} element
 */
Address.prototype.buildFromElement = function (element) {
  let params = JSON.parse(
    element.getAttribute(DataAttributesConstant.favoritesParams)
  );

  this.setAddress(
    params.name,
    params.address,
    params.latitude,
    params.longitude
  );
};
