import { ControllerInterface } from "./controller-interface";

export function BookmarkDisabledMessageController() {
  this.rootElement = null;
}

// Héritage du prototype de l'interface
BookmarkDisabledMessageController.prototype =
  Object.create(ControllerInterface);

/**
 * This controller is call by app if the favorite are disabled by the user
 * @param {HTMLElement} rootElement
 */
BookmarkDisabledMessageController.prototype.init = function (rootElement) {
  this.rootElement = rootElement;
  rootElement.innerHTML =
    '<div id="list-favorites">\n' +
    '         <ul class="list-fav">\n' +
    "            <li>\n" +
    `               ${Translator.trans("bookmark.disabled")}\n` +
    "            </li>\n" +
    "         </ul>\n" +
    "      </div>";
};

BookmarkDisabledMessageController.prototype.destroy = function () {
  // Code pour nettoyer la page des favoris désactivés
  this.rootElement.innerHTML = "";
};
