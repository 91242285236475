import { Component } from "../componant";
import { AddressMenuComponent } from "./address-menu-component";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import { NoBookmarkTabContentComponent } from "./no-bookmark-tab-content-component";
import {addSimplebarEventListener} from "../../simplebar";
import {getAccessibilityEnterAwareClassname} from "bookmark_bundle/subscriber/accessibility-subscriber";

export function AddressTabContentComponent(props) {
  Component.call(this, props);
  this.bookmarkCollections = [];
  this.classname = "AddressTabContentComponent";
}

AddressTabContentComponent.prototype = Object.create(Component.prototype);

AddressTabContentComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.classList.add("address-tab-content");
  this.addBottomButton(container);

  let client = new BookmarkClientFactory().createClient();
  this.bookmarkCollections = client.getSync();

  this.bookmarkCollections = this.bookmarkCollections.filter((bookmark) => {
    return bookmark.type.includes("address");
  });

  if (this.bookmarkCollections.length === 0) {
    const noBookmarkTabContent = new NoBookmarkTabContentComponent();
    noBookmarkTabContent.mount(container);
    return container;
  }

  const addressSection = this.renderAddressSection();

  container.appendChild(addressSection);
  addSimplebarEventListener(this.classname);
  return container;
};

AddressTabContentComponent.prototype.addBottomButton = function (container) {
  const bottomDiv = document.createElement("div");
  bottomDiv.classList.add("button", "bottom", getAccessibilityEnterAwareClassname());
  bottomDiv.setAttribute("tabindex", 0);

  bottomDiv.addEventListener("click", (event) => {
    window.AppBookmarkInstance.router.route("/addresses/new");
    event.stopPropagation();
  });

  bottomDiv.textContent = Translator.trans("bookmark.address.button.new");
  container.appendChild(bottomDiv);
};

AddressTabContentComponent.prototype.renderHeader = function (title) {
  const header = document.createElement("div");
  header.classList.add("header");

  const headerTitle = document.createElement("h2");
  headerTitle.classList.add("header-title");
  headerTitle.textContent = title;

  header.appendChild(headerTitle);

  return header;
};

AddressTabContentComponent.prototype.renderAddressSection = function () {
  const section = document.createElement("section");
  section.classList.add("section-address");

  const container = document.createElement("div");
  container.classList.add("address-container");

  this.bookmarkCollections.forEach((bookmark) => {
    const someAddress = this.renderAddressItem(bookmark);
    container.appendChild(someAddress);
  });
  section.appendChild(container);

  return section;
};

AddressTabContentComponent.prototype.renderAddressItem = function (bookmark) {
  const addressItem = document.createElement("div");
  addressItem.classList.add("address-item", "shared-item", "deletable");

  const addressContent = document.createElement("div");
  addressContent.classList.add("content", "no-margin-top");

  const addressTitle = document.createElement("strong");
  addressTitle.classList.add("address-title");
  addressTitle.textContent = bookmark.data.name;
  const icon = document.createElement("i");
  icon.classList.add("ikisio", bookmark.data.icon ?? "ikisio-address");

  addressTitle.prepend(icon);

  const addressText = document.createElement("div");
  addressText.classList.add("address-text");
  addressText.textContent = bookmark.data.address;

  const header = document.createElement("div");
  header.classList.add("header");

  // Création du menu
  const menu = new AddressMenuComponent({
    id: bookmark.id,
    componentUpdate: this.update.bind(this),
  }).render();
  header.appendChild(menu);

  header.appendChild(addressTitle);
  addressContent.appendChild(addressText);

  addressItem.appendChild(header);
  addressItem.appendChild(addressContent);

  return addressItem;
};
