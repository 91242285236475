import { EventConstant } from "../events/constants";
import { BookmarkClientFactory } from "../client/bookmark-client-factory";
import { BookmarkBuilder } from "../builder/model/bookmarkBuilder";

let instance = null;

export function getAddBookmarkSubscriber() {
  if (!instance) {
    instance = new AddBookmarkSubscriber();
  }
  return instance;
}

function AddBookmarkSubscriber() {
  this.handler = this.handleBookmarkClick.bind(this);
  this.subscribed = false;
}

AddBookmarkSubscriber.prototype.subscribe = function () {
  if (!this.subscribed) {
    this.subscribed = true;
    document.addEventListener(
      EventConstant.add_bookmark_event_name,
      this.handler
    );
  }
};

AddBookmarkSubscriber.prototype.unsubscribe = function () {
  if (this.subscribed) {
    document.removeEventListener(
      EventConstant.add_bookmark_event_name,
      this.handler
    );
    this.subscribed = false;
  }
};

AddBookmarkSubscriber.prototype.handleBookmarkClick = function (event) {
  const bookmarkElement = event.detail.element;

  const bookmarkClient = new BookmarkClientFactory().createClient();
  const bookmarkBuilder = new BookmarkBuilder();
  let bookmark = bookmarkBuilder.build(bookmarkElement);
  // updated bookmark events, perhaps reload bookmark any days ? (you should fetch all bookmarks)
  bookmarkClient.create(bookmark, () => {
    const event = new CustomEvent(EventConstant.posted_bookmark_event_name, {detail: {bookmark: bookmark}});
    document.dispatchEvent(event);
  });
};
