export function Component(props) {
  this.props = props || {};
  this.state = {};
  this.dom = null;
  this.current = null;
  this.classname = "Component";
}

Component.prototype.render = function () {
  throw new Error("Render method must be implemented");
};

Component.prototype.mount = function(container) {
  this.current = this.render();
  container.appendChild(this.current);
  const customEvent = new CustomEvent("component-mounted",{
    detail: { componentName: this.classname, current: this.current, self: this, method: "mount" }
  });
  document.dispatchEvent(customEvent);
  this.dom = container;
};

Component.prototype.update = function () {
  const current = this.render();
  this.current.replaceWith(current);
  this.current = current;
  const customEvent = new CustomEvent("component-mounted",{
    detail: { componentName: this.classname, current: this.current, self: this, method: "updated" },
  });
  document.dispatchEvent(customEvent);
};

Component.prototype.setState = function (newState) {
  this.state = Object.assign({}, this.state, newState);
  this.update();
};
