import { Component } from "../componant";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import { getAccessibilityEnterAwareClassname } from "../../subscriber/accessibility-subscriber";

function MenuComponent(props) {
  Component.call(this, props);
  this.props.onMenuClick =
    this.props.onMenuClick || this.defaultMenuClickHandler.bind(this);
}

MenuComponent.prototype = Object.create(Component.prototype);

MenuComponent.prototype.defaultMenuClickHandler = function (event) {
  const menu = event.currentTarget;
  menu.classList.add("active");

  let client = new BookmarkClientFactory().createClient();

  if (document.querySelector(".remove-favorite")) {
    this.closePopup(event);
    return;
  }

  const overlay = document.createElement("div");
  overlay.classList.add("overlay-remove-favorite");
  menu.parentNode.appendChild(overlay);

  const removeFavoriteDiv = document.createElement("div");
  removeFavoriteDiv.classList.add("remove-favorite");
  removeFavoriteDiv.style.position = "absolute";

  const removeFavoriteLink = document.createElement("a");
  removeFavoriteLink.classList.add("do-remove-favorite");
  removeFavoriteLink.href = "#";
  removeFavoriteLink.innerHTML =
    `<i class="ikisio ikisio-trash"></i> ${Translator.trans("bookmark.menu.button.delete")}`;
  removeFavoriteDiv.appendChild(removeFavoriteLink);
  removeFavoriteDiv.addEventListener("click", (event) => {
    client.delete(this.props.id).then(() => {
      // menu.closest(".deletable").remove();
      if (this.props.componentUpdate) {
        this.props.componentUpdate();
      }
    });
    event.stopPropagation();
  });

  menu.parentNode.appendChild(removeFavoriteDiv);
  removeFavoriteDiv.focus();

  const closeDiv = function (event) {
    if (event.target !== removeFavoriteLink && !menu.contains(event.target)) {
      this.closePopup(event);
    }
  };
  overlay.addEventListener("click", closeDiv.bind(this));
  event.stopPropagation();
};

MenuComponent.prototype.closePopup = function (event) {
  const menu = event.currentTarget;
  menu.classList.remove("active");

  document.querySelector(".remove-favorite").remove();
  document.querySelector(".overlay-remove-favorite").remove();
  event.stopPropagation();
};

MenuComponent.prototype.render = function () {
  const menuDiv = document.createElement("div");
  menuDiv.setAttribute("tabindex", 0);
  menuDiv.classList.add("right", "menu", getAccessibilityEnterAwareClassname());
  menuDiv.setAttribute("title", Translator.trans("bookmark.menu.openclose"));

  const img = document.createElement("img");
  img.src = "/bundles/canaltppnpcore/images/menu.svg";
  img.alt = "";
  menuDiv.appendChild(img);

  menuDiv.addEventListener("click", this.props.onMenuClick);

  return menuDiv;
};

MenuComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  this.dom = container;
  this.current = current;
};

export { MenuComponent };
