import { getAddBookmarkSubscriber } from "./subscriber/add-bookmark-subscriber";
import { getDeleteBookmarkSubscriber } from "./subscriber/delete-bookmark-subscriber";
import { getAccessibilitySubscriber } from "./subscriber/accessibility-subscriber";

import { Router } from "./router/router";
import { routeConfig } from "./config/route-config";
import {
  setConfig,
  getCache,
  getEnabledBookmark,
  getUserConsent,
  getRootContentId,
  getEnv,
} from "./config";
import { CreateFixtureCommand } from "./__fixtures__/fixture-command";

/**
 * Creates a new instance of the App.
 * @constructor
 */
export function App() {
  this.router = new Router();
}

/**
 * Initializes the App with the given configuration.
 * @param {Object} options - The configuration options for initializing the App.
 * @param {boolean} [options.userConsent=true] - Whether the user has given their consent.
 * @param {boolean} [options.enabledBookmark=true] - Whether bookmarks are enabled.
 * @param {string} [options.rootContentId='bookmark_container'] - The ID of the root content element for the App.
 * @param {boolean} [options.activeCache=true] - Whether the cache is active.
 */
App.prototype.init = function ({
  userConsent = true,
  enabledBookmark = true,
  rootContentId = "bookmark_container",
  activeCache = true,
  providerName = "localstorage",
}) {
  // Set global configurations
  setConfig({
    cache: activeCache,
    enabledBookmark,
    userConsent,
    rootContentId,
    providerName,
  });

  // Initialize the router with the route configuration and root content element.
  this.router.init(routeConfig, document.querySelector(getRootContentId()));

  // Set the path for the router based on the user's consent and whether bookmarks are enabled.
  let path = "/bookmark-disabled";
  if (getUserConsent() && getEnabledBookmark()) {
    path = "/home";
  }

  // Route to the appropriate controller based on the path.
  this.router.route(path);
  return this;
};

App.prototype.attachEvents = function () {
  getAddBookmarkSubscriber().subscribe();
  getDeleteBookmarkSubscriber().subscribe();
  getAccessibilitySubscriber().subscribe();
  if (getEnv() === "dev") {
    CreateFixtureCommand();
  }
};

App.prototype.detachEvents = function () {
  getAddBookmarkSubscriber().unsubscribe();
  getDeleteBookmarkSubscriber().unsubscribe();
  getAccessibilitySubscriber().unsubscribe();
};

App.prototype.destroy = function () {
  this.detachEvents();
  return this;
};

document.addEventListener("DOMContentLoaded", function () {
  if (!window.AppBookmarkInstance) {
    window.AppBookmarkInstance = new App();
    window.AppBookmarkInstance.attachEvents();
  }

  document.body.addEventListener("bookmark_container_displayed", function (e) {
    document.title = Translator.trans('bookmark.title');
    Kisio.section_active = 'bookmark';
    window.AppBookmarkInstance.init({
      userConsent: Kisio.user_consent == 1,
      enabledBookmark: Kisio.enable_favorites == 1,
      rootContentId: "#ctp-favorites",
    });
  });

  document.body.dispatchEvent(new CustomEvent("bookmark_link_init"));

  document.body.addEventListener("bookmark_standalone_display", function(e) {
    e.preventDefault();
    let section = e.detail.element.dataset.section;
    let url = window.location.origin + window.location.pathname;

    if (Kisio.type_product === 'standalone') {
      window.history.replaceState('', 'bookmark', url.replace('result/', ''));

      // Event hide active section
      if (url.indexOf('proximity') !== -1) {
        document.body.dispatchEvent(new CustomEvent('proximity_close_section'));
      } else {
        document.body.dispatchEvent(new CustomEvent(`${section}_container_close`));
      }

      // Event show bookmark
      document.body.dispatchEvent(new CustomEvent("standalone_display_bookmark", {detail: {section}}));
    } else {
      const indexString = url.indexOf(Kisio.type_product);
      if (url.indexOf('proximity') !== -1) {
        document.body.dispatchEvent(new CustomEvent('proximity_close_section'));
      }
      
      if (indexString !== -1) {
        const updatedUrl = url.slice(0, indexString);
        window.history.replaceState('', 'bookmark', updatedUrl+Kisio.type_product+'/');
      }

      document.body.dispatchEvent(new CustomEvent("displayBookmark"));
    }
  })
});

document.body.addEventListener('bookmark_link_init', function (e) {
  let elements = document.querySelectorAll('.favorites_standalone_link');
  if (!elements) {
    return;
  }
  
  elements.forEach(element => {
    element.addEventListener('click', (e) => {
      document.body.dispatchEvent(new CustomEvent("bookmark_standalone_display", {detail : {element}}));
    })
  });
})