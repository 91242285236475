import { Bookmark } from "../model/bookmark";
import { LocalStorageBookmarkClient } from "../client/local-storage-bookmark-client";

export function CreateFixtureCommand() {
  window.console.createBookmark = async function (data) {
    try {
      const bookmark = new Bookmark(data); // Créez un nouvel objet Bookmark avec les données fournies
      const bookmarkClient = new LocalStorageBookmarkClient(); // Créez une instance de LocalStorageBookmarkClient
      await bookmarkClient.create(bookmark); // Ajoutez le nouveau marque-page à la liste des marque-pages existants
    } catch (error) {
      console.error(error);
    }
  };
}
