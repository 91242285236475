import { BookmarkDisabledMessageController } from "../controller/bookmark-disabled-message-controller";
import { BookmarkHomeController } from "../controller/bookmark-home-controller";
import { UserConsentDisabledMessageController } from "../controller/user-consent-disabled-message-controller";
import { BookmarkAddressController } from "../controller/bookmark-address-controller";

export const routeConfig = [
  { path: "/home", controller: BookmarkHomeController },
  { path: "/home/:tabId", controller: BookmarkHomeController },
  { path: "/bookmark-disabled", controller: BookmarkDisabledMessageController },
  {
    path: "/user-consent-disabled",
    controller: UserConsentDisabledMessageController,
  },
  { path: "/addresses/new", controller: BookmarkAddressController },
  { path: "/addresses/edit/:id", controller: BookmarkAddressController },
];
