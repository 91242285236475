import { Component } from "../componant";
import { getAccessibilityEnterAwareClassname } from "../../subscriber/accessibility-subscriber";
const tabs = [
  {
    id: "pt_object_title",
    title: Translator.trans("bookmark.tab.title.transport"), // "Transport",
    standalone: ["schedule", "proximity"],
    ariacontrols: '',
  },
  {
    id: "tab_journey_title",
    title: Translator.trans("bookmark.tab.title.journey"), // "Itinéraires",
    standalone: ['journey'],
    ariacontrols: '',
  },
  {
    id: "address_title",
    title: Translator.trans("bookmark.tab.title.address"), // "Adresses",
    standalone: ['journey', "proximity"],
    ariacontrols: '',
  },
];

export function BookmarkTitleComponent(props) {
  Component.call(this, props);

  let activeTab = this.props.selectedTabId || tabs[0].id;

  if (Kisio.type_product == "standalone") {
    activeTab = tabs.find(tab => tab.standalone.includes(Kisio.sectionOrigin)).id;
  }

  this.state = {
    selectedTabId: activeTab,
  };

}

BookmarkTitleComponent.prototype = Object.create(Component.prototype);

BookmarkTitleComponent.prototype.handleTabClick = function (id) {
  return function () {
    this.setState({
      selectedTabId: id,
    });
    this.props.onChange(id);
  }.bind(this);
};

BookmarkTitleComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.id = "bookmark_title_container";
  container.classList.add("clearfix", "open");
  container.setAttribute("role", "tablist");
  container.style.display = "flex";

  tabs.forEach((tab) => {

    if (Kisio.type_product == "standalone" && !tab.standalone.includes(Kisio.sectionOrigin)) {
      return;
    }

    const div = document.createElement("div");
    div.id = tab.id;
    div.classList.add("bookmark_title", getAccessibilityEnterAwareClassname());
    if (this.state.selectedTabId === tab.id) {
      div.classList.add("selected");
      div.setAttribute("aria-selected", "true");
      div.setAttribute("tabindex", "0");
    } else {
      div.setAttribute("aria-selected", "false");
      div.setAttribute("tabindex", "0");
    }
    div.setAttribute("role", "tab");
    
    div.onclick = this.handleTabClick(tab.id);

    const span = document.createElement("span");
    span.classList.add("title");
    span.textContent = tab.title;

    div.appendChild(span);
    container.appendChild(div);
  });

  return container;
};

BookmarkTitleComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  this.dom = container;
  this.current = current;
};
