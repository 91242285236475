import { ControllerInterface } from "./controller-interface";
import { BookmarkTitleComponent } from "../components/bookmark-component/bookmark-title-component";
import { BookmarkClientFactory } from "../client/bookmark-client-factory";
import { AddressNewComponent } from "../components/bookmark-component/adress-new-component";
import { BookmarkDisabledMessageController } from "./bookmark-disabled-message-controller";
import { BookmarkHomeController } from "./bookmark-home-controller";

export function BookmarkAddressController() {
  this.bookmarkClient = new BookmarkClientFactory().createClient();
}

// Héritage du prototype de l'interface
BookmarkAddressController.prototype = Object.create(ControllerInterface);

/**
 * Controler create a new Adress or edit one (check config)
 * @param rootElement
 * @param {object} params
 */
BookmarkAddressController.prototype.init = function (rootElement, params) {
  this.rootElement = rootElement;

  let bookmark = null;
  if (params.id) {
    bookmark = this.bookmarkClient.getOneSync(params.id);
  }

  let addressComponent = new AddressNewComponent({
    bookmark: bookmark,
  });
  addressComponent.mount(rootElement);
};

BookmarkAddressController.prototype.destroy = function () {
  this.rootElement.innerHTML = "";
};
