import { NavitiaBuilder } from "../navitia/navitia-builder";
import { JourneySolutionBuilder } from "../navitia/journey-solution";
import { Bookmark } from "../../model/bookmark";
import { DataAttributesConstant } from "../../components/bookmark-star/constants";

export function BookmarkBuilder() {
  this.bookmark = new Bookmark();
}

/**
 * @param {Element} element
 * @returns Bookmark
 */
BookmarkBuilder.prototype.build = function (element) {
  const type = element.getAttribute(DataAttributesConstant.favoritesType);
  this.buildType(type).buildDataAndId(type, element);
  return this.bookmark;
};

BookmarkBuilder.prototype.buildType = function (type) {
  this.bookmark.setType(type);
  return this;
};

/**
 *
 * @param {string} type
 * @param {Element} element
 * @returns {BookmarkBuilder}
 */
BookmarkBuilder.prototype.buildDataAndId = function (type, element) {
  let bookmarkBuilder;
  let navitiaBuilder = new NavitiaBuilder();

  switch (type) {
    case "stop_area_lines_direction":
      bookmarkBuilder = navitiaBuilder.stopAreas();
      break;
    case "stop_point":
      bookmarkBuilder = navitiaBuilder.stopPoint();
      break;
    case "journey_solution":
      bookmarkBuilder = navitiaBuilder.journeySolution();
      break;
    case "poi":
      bookmarkBuilder = navitiaBuilder.poi();
      break;

    default:
      throw new Error("Unknown bookmark type");
  }

  bookmarkBuilder.buildFromElement(element);
  let navitia = bookmarkBuilder.getNavitiaObject();
  this.bookmark.setData(navitia.data);
  this.bookmark.setId(navitia.id);

  return this;
};
