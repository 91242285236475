import { EventConstant } from "../events/constants";
import { BookmarkClientFactory } from "../client/bookmark-client-factory";
import { BookmarkBuilder } from "../builder/model/bookmarkBuilder";

let instance = null;

export function getDeleteBookmarkSubscriber() {
  if (!instance) {
    instance = new DeleteBookmarkSubscriber();
  }
  return instance;
}

function DeleteBookmarkSubscriber() {
  this.handler = this.handleBookmarkClick.bind(this);
  this.subscribed = false;
}

DeleteBookmarkSubscriber.prototype.subscribe = function () {
  if (!this.subscribed) {
    this.subscribed = true;

    document.addEventListener(
      EventConstant.delete_bookmark_event_name,
      this.handler
    );
  }
};

DeleteBookmarkSubscriber.prototype.unsubscribe = function () {
  if (this.subscribed) {
    document.removeEventListener(
      EventConstant.delete_bookmark_event_name,
      this.handler
    );
    this.subscribed = false;
  }
};

DeleteBookmarkSubscriber.prototype.handleBookmarkClick = function (event) {
  const bookmarkElement = event.detail.element;

  const bookmarkClient = new BookmarkClientFactory().createClient();
  const bookmarkBuilder = new BookmarkBuilder();
  let bookmark = bookmarkBuilder.build(bookmarkElement);

  // updated bookmark events, perhaps reload bookmark any days ? (you should fetch all bookmarks)
  bookmarkClient.delete(bookmark.getId(), () => {
    const event = new CustomEvent(EventConstant.deleted_bookmark_event_name);
    document.dispatchEvent(event);
  });
};
