import { Component } from "../componant";
import { Address } from "../../builder/navitia/address";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import { ReturnBookmarkComponent } from "./return-bookmark-component";
import {getAccessibilityEnterAwareClassname} from "bookmark_bundle/subscriber/accessibility-subscriber";

export function AddressNewComponent(props) {
  Component.call(this, props);
  this.client = new BookmarkClientFactory().createClient();
}

const AUTOCOMPLETE_ID = "autocomplete-bookmark";

const AUTOCOMPLETE_HIDDEN_ID = "autocomplete-bookmark-hidden";

const NAME_ID = "name-bookmark";

AddressNewComponent.prototype = Object.create(Component.prototype);

AddressNewComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.classList.add("adress-new-container");

  let returnBookmarkComponent = new ReturnBookmarkComponent({
    path: "/home/address_title",
  });
  returnBookmarkComponent.mount(container);

  const title = document.createElement("h3");
  title.textContent = Translator.trans("bookmark.section.title.new_address"); // "Ajouter une adresse favorite

  const form = this.renderAdressNewForm();

  container.appendChild(title);
  container.appendChild(form);
  this.addBottomButton(container);
  this.attachAutocompleteOnMountEvents();
  return container;
};

AddressNewComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  const customEvent = new CustomEvent("component-mounted", {
    detail: { componentName: "AddressNewComponent" },
  });
  document.dispatchEvent(customEvent);
  this.dom = container;
  this.current = current;
};

AddressNewComponent.prototype.attachAutocompleteOnMountEvents = function () {
  document.addEventListener("component-mounted", function (event) {
    let bookmarkInput = JKisio(`#${AUTOCOMPLETE_ID}`);
    bookmarkInput.autocomplete();
    Kisio.Callbacks.autocomplete();
  });
};

AddressNewComponent.prototype.renderAdressNewForm = function () {
  const form = document.createElement("form");
  form.classList.add("address-new-form", "autocomplete");

  // Input Nom avec icône ikisio-name
  const inputNameWrapper = document.createElement("div");
  inputNameWrapper.classList.add("input-wrapper");

  const iconName = document.createElement("i");
  iconName.classList.add("ikisio-name", "ikisio", this.props?.bookmark?.data?.icon ?? "ikisio-address");
  inputNameWrapper.appendChild(iconName);

  const inputName = document.createElement("input");
  if (this.props?.bookmark?.data?.name) {
    inputName.value = this.props.bookmark.data.name;
  }
  inputName.id = NAME_ID;
  inputName.setAttribute("type", "text");
  inputName.setAttribute("aria-label", Translator.trans("bookmark.address.form.name"));
  inputName.setAttribute("title", Translator.trans("bookmark.address.form.name"));
  inputName.setAttribute("placeholder", Translator.trans("bookmark.address.placeholder.name"));
  inputNameWrapper.appendChild(inputName);

  form.appendChild(inputNameWrapper);

  // Input Adresse avec icône ikisio-informations
  const inputAddressWrapper = document.createElement("div");
  inputAddressWrapper.classList.add("input-wrapper", "autocomplete");

  const iconAddress = document.createElement("i");
  iconAddress.classList.add("ikisio-name", "ikisio", "ikisio-zoom");
  inputAddressWrapper.appendChild(iconAddress);

  const inputAddress = document.createElement("input");
  inputAddress.setAttribute("aria-label", Translator.trans("bookmark.address.form.address"));
  inputAddress.setAttribute("title", Translator.trans("bookmark.address.form.address"));

  inputAddress.id = AUTOCOMPLETE_ID;
  if (this.props?.bookmark?.data?.address) {
    inputAddress.value = this.props.bookmark.data.address;
  }
  inputAddress.classList.add("autocompletable");
  inputAddress.setAttribute("origin", "traffic");
  inputAddress.setAttribute("length", 1);
  inputAddress.setAttribute("disable-history", true);
  inputAddress.setAttribute("type", "text");
  inputAddress.setAttribute("placeholder", Translator.trans("bookmark.address.placeholder.address"));
  inputAddress.setAttribute("show-marker", false);

  inputAddress.dataset.group = "bookmark";
  inputAddress.dataset.width_mode = "outerWidth";

  inputAddressWrapper.appendChild(inputAddress);

  form.appendChild(inputAddressWrapper);

  const inputAddressCoordHidden = document.createElement("input");
  inputAddressCoordHidden.id = AUTOCOMPLETE_HIDDEN_ID;
  inputAddressCoordHidden
  if (this.props?.bookmark?.data) {
    inputAddressCoordHidden.value = `${this.props.bookmark.data.latitude};${this.props.bookmark.data.longitude}`;
  }
  inputAddressCoordHidden.setAttribute("origin", "bookmark");
  inputAddressCoordHidden.setAttribute("type", "hidden");
  inputAddressCoordHidden.classList.add("autocompletable-hidden");
  inputAddressWrapper.appendChild(inputAddressCoordHidden);

  return form;
};

AddressNewComponent.prototype.addBottomButton = function (container) {
  const bottomDiv = document.createElement("div");
  bottomDiv.classList.add("button", getAccessibilityEnterAwareClassname());
  bottomDiv.setAttribute("tabindex", 0);
  bottomDiv.setAttribute("type", "button");
  bottomDiv.setAttribute("role", "button");
  bottomDiv.setAttribute("aria-pressed", true);
  bottomDiv.setAttribute("aria-label", Translator.trans("bookmark.address.button.add"));

  bottomDiv.addEventListener("click", (event) => {
    if (this.validate() === true && this.validateAddressBookmark()) {
      this.createAddressBookmark(
        document.getElementById(NAME_ID).value,
        document.getElementById(AUTOCOMPLETE_ID).value,
        document.getElementById(AUTOCOMPLETE_HIDDEN_ID).value
      ).then(() => {
        window.AppBookmarkInstance.router.route("/home/address_title");
      });
      event.stopPropagation();
    }
  });

  bottomDiv.textContent =  Translator.trans("bookmark.address.button.add");
  container.appendChild(bottomDiv);
};

/**
 *
 * @param {string}name
 * @param {string}address
 * @param {string}coordString
 * @returns {Promise<void>}
 */
AddressNewComponent.prototype.createAddressBookmark = async function (
  name,
  address,
  coordString
) {
  const coords = coordString.split(";"); // Split the coordString using the semicolon as the delimiter
  const longitude = parseFloat(coords[0]);
  const latitude = parseFloat(coords[1]);
  const addressObject = new Address();
  addressObject.setAddress(name, address, latitude, longitude);
  if (this.props?.bookmark?.id) {
    // update
    return this.client.update(
      this.props?.bookmark?.id,
      addressObject.getNavitiaObject()
    );
  }
  return this.client.create(addressObject.getNavitiaObject());
};

AddressNewComponent.prototype.validate = function () {
  let isValid = true;

  // Validate name input
  const nameInput = document.getElementById(NAME_ID);
  if (nameInput.value.trim() === "") {
    nameInput.classList.add("error");
    isValid = false;
  } else {
    nameInput.classList.remove("error");
  }

  // Validate address input
  const addressInput = document.getElementById(AUTOCOMPLETE_ID);
  if (addressInput.value.trim() === "") {
    addressInput.classList.add("error");
    isValid = false;
  } else {
    addressInput.classList.remove("error");
  }

  return isValid;
};

AddressNewComponent.prototype.validateAddressBookmark = function () {
  const addressesBookmark = this.client
    .getSync()
    .filter((bookmark) => bookmark.type === "address");

  const isNameExist = this.validateInput(
    NAME_ID,
    (value) =>
      addressesBookmark.some(
        (addressBookmark) =>
          addressBookmark.data.name === value &&
          addressBookmark.id !== this.props?.bookmark?.id
      ) === false,
      Translator.trans("bookmark.address.validator.name_already_exist")
  );

  const isAddressExist = this.validateInput(
    AUTOCOMPLETE_ID,
    (value) =>
      addressesBookmark.some(
        (addressBookmark) =>
          addressBookmark.data.address === value &&
          addressBookmark.id !== this.props?.bookmark?.id
      ) === false,
      Translator.trans("bookmark.address.validator.address_already_exist")
  );

  return isNameExist && isAddressExist;
};

AddressNewComponent.prototype.validateInput = function (
  inputId,
  validator,
  errorMessage
) {
  const input = document.getElementById(inputId);
  const valid = validator(input.value);
  let errorElement = input.nextElementSibling;

  if (valid) {
    input.closest(".input-wrapper").classList.remove("error-message");
    if (errorElement && errorElement.classList.contains("error")) {
      errorElement.remove();
    }
  } else {
    input.closest(".input-wrapper").classList.add("error-message");
    if (!errorElement || !errorElement.classList.contains("error")) {
      errorElement = document.createElement("div");
      errorElement.classList.add("error");
      input.parentNode.insertBefore(errorElement, input.nextSibling);
    }
    errorElement.textContent = errorMessage;
  }

  return valid;
};

AddressNewComponent.prototype.validate = function () {
  const isNameValid = this.validateInput(
    NAME_ID,
    (value) => value.trim() !== "",
      Translator.trans("bookmark.address.validator.empty_name")
  );

  const isAddressValid = this.validateInput(
      AUTOCOMPLETE_ID,
      (value) => value.trim() !== "",
      Translator.trans("bookmark.address.validator.empty_address")
  );

  const isAutoCompletionValid = this.validateInput(
    AUTOCOMPLETE_HIDDEN_ID,
    (value) => value.trim() !== "",
      Translator.trans("bookmark.address.validator.autocompletion")
  );

  return isNameValid && isAddressValid && isAutoCompletionValid;
};
