import { Component } from "../componant";
import { TransportTabContentComponent } from "./transport-tab-content-component";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import {getAccessibilityEnterAwareClassname} from "bookmark_bundle/subscriber/accessibility-subscriber";

/**
 * Manage the Menu of address, a little more complexe then the main menu for other tabs
 * Todo : can merge some method : constructor & delete option
 * @param props
 * @constructor
 */
function AddressMenuComponent(props) {
  Component.call(this, props);
  this.props.onMenuClick =
    this.props.onMenuClick || this.defaultMenuClickHandler.bind(this);
  this.client = new BookmarkClientFactory().createClient();
}

AddressMenuComponent.prototype = Object.create(Component.prototype);

AddressMenuComponent.prototype.defaultMenuClickHandler = function (event) {
  const menu = event.currentTarget;

  menu.classList.add("active");
  if (document.querySelector(".remove-favorite")) {
    this.closePopup(menu);
    return;
  }

  const overlay = document.createElement("div");
  overlay.classList.add("overlay-remove-favorite");
  menu.parentNode.appendChild(overlay);

  const removeFavoriteDiv = document.createElement("div");
  removeFavoriteDiv.classList.add("remove-favorite");
  removeFavoriteDiv.style.position = "absolute";

  const modifiyLink = document.createElement("a");
  modifiyLink.classList.add("do-change");
  modifiyLink.href = "#";
  modifiyLink.innerHTML = '<i class="ikisio ikisio-settings"></i> ' + Translator.trans("bookmark.menu.button.modify");

  modifiyLink.addEventListener("click", () => {
    window.AppBookmarkInstance.router.route(`/addresses/edit/${this.props.id}`);
    event.stopPropagation();
  });

  const removeFavoriteLink = document.createElement("a");
  removeFavoriteLink.classList.add("do-remove-favorite");
  removeFavoriteLink.href = "#";
  removeFavoriteLink.innerHTML =
    '<i class="ikisio ikisio-trash"></i> ' +  Translator.trans("bookmark.menu.button.delete");;

  removeFavoriteDiv.appendChild(modifiyLink);
  removeFavoriteDiv.appendChild(removeFavoriteLink);

  removeFavoriteLink.addEventListener("click", () => {
    this.client.delete(this.props.id).then(() => {
      // menu.closest(".deletable").remove();
      if (this.props.componentUpdate) {
        this.props.componentUpdate();
      }
    });
  });

  menu.parentNode.appendChild(removeFavoriteDiv);

  const closeDiv = function (event) {
    if (event.target !== removeFavoriteLink && !menu.contains(event.target)) {
      this.closePopup(menu);
    }
  };
  overlay.addEventListener("click", closeDiv.bind(this));
};

AddressMenuComponent.prototype.closePopup = function (menu) {
  document.querySelector(".remove-favorite").remove();
  document.querySelector(".overlay-remove-favorite").remove();
  menu.classList.remove("active");
};

AddressMenuComponent.prototype.render = function () {
  const menuDiv = document.createElement("div");
  menuDiv.setAttribute("tabindex", 0);
  menuDiv.classList.add("right", "menu", getAccessibilityEnterAwareClassname());
  menuDiv.setAttribute("title", Translator.trans("bookmark.menu.openclose"));

  const img = document.createElement("img");
  img.src = "/bundles/canaltppnpcore/images/menu.svg";
  img.alt = "";
  menuDiv.appendChild(img);

  menuDiv.addEventListener("click", this.props.onMenuClick);

  return menuDiv;
};

AddressMenuComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  this.dom = container;
  this.current = current;
};

export { AddressMenuComponent };
