import { Component } from "../componant";

export function NoBookmarkTabContentComponent(props) {
  Component.call(this, props);
}

NoBookmarkTabContentComponent.prototype = Object.create(Component.prototype);

NoBookmarkTabContentComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.classList.add("no-bookmark-content");

  const p = document.createElement("div");
  p.innerHTML = Translator.trans("bookmark.tab.no_bookmark");

  container.appendChild(p);

  return container;
};

NoBookmarkTabContentComponent.prototype.mount = function (container) {
  let current = this.render();
  container.appendChild(current);
  this.dom = container;
  this.current = current;
};
